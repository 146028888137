import Link from 'next/link';
import { selectors } from 'lib/entities/credentials';
import { useSelector } from 'react-redux';
import If from 'global/ErrorHandlers/If';
import { menus } from './constants';
import { MobileMenuWrapper, MobileNav } from './styled';
import BetaLabel from './Beta';

const MobileMenu = () => {
  const { isLoggedIn } = useSelector(selectors);
  return (
    <MobileMenuWrapper>
      <div>
        {menus.map((menu) => (
          <Link key={menu.title} href={menu.url} passHref>
            <MobileNav href={menu.url}>
              {menu.title}
            </MobileNav>
          </Link>
        ))}
        <If condition={!isLoggedIn}>
          <Link href="/login">
            <MobileNav uppercase>Log In</MobileNav>
          </Link>
          <Link href="/register">
            <MobileNav uppercase>Sign Up</MobileNav>
          </Link>
        </If>
      </div>
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
