/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
/* eslint-disable space-infix-ops */
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import { Common, device, color, utils } from '@stockbit/ui';
import {
  LogoProps,
  HamburgerMenuProps,
  MobileNavProps,
  MenuAuthProps,
} from './styled.types';

const { Flex } = Common;
const { hexOpacity } = utils;

const animatedlogo = keyframes`
  from {
      filter: grayscale(100%);
      opacity: 0.4;
      transform: scale(0.9);
    }
    50% {
      filter: grayscale(100%);
      transform: scale(1.1);
    }
    to {
      filter: grayscale(10%);
      opacity: 1;
      transform: scale(1);
    }
`;

const GlobalStyle = createGlobalStyle`
  * {
      box-sizing: border-box;
  }

  html, body {
    width: 100%;
  }

  html {
    line-height: 1.15;
     background: ${({ theme }) => theme?.background};
  }

  body {
     &::-webkit-scrollbar {
        ${({ theme }) => theme?.name === 'dark' && 'display: none'};
      }
    position: relative;
    width: 100%;
    font-family: 'ProximaNovaRg';
    color:${({ theme }) => theme?.text};
    background: ${({ theme }) => theme?.background};
    overflow-x: hidden !important;
    overflow-y: visible !important;
    font-size: 17px;
    margin: 0;
    padding: 0;
    height: initial;
  }

  div {
    padding: 0;
    margin: 0;
  }

  table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  td {
    display: table-cell;
    vertical-align: inherit;
  }

  audio, video {
    display: inline-block;
  }

  img {
    border-style: none;
  }

  .fclear:after {
    content: '';
    clear: both;
    display: table;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100% !important;
  display: block;
`;

const HeaderLanding = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background: ${({ theme }) => theme?.background};
`;

const HeaderWrapper = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  mx: 'auto',
})`
  padding-left: 25px;
  padding-right: 25px;
  max-width: 1300px;

  @media ${device.laptop} {
    width: calc(100% - 150px);
    padding: 5px 0;
  }
`;

const Logo = styled.div<LogoProps>`
  margin: 15px 0;
  cursor: pointer;

  @media ${device.laptop} {
    height: 66px;
  }

  img {
    height: 42px;

    @media ${device.laptop} {
      height: 60px;
      width: 121.11px;
    }
  }

  &.small-logo {
    height: 45px;
    display: flex;
    align-items: center;
    img {
      height: 31.5px;
      animation-timing-function: linear;
      animation-name: ${animatedlogo};
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
    }
  }
`;

const LogoMahakarya = styled.div`
  img {
    display: none;

    @media ${device.laptop} {
      display: block;
      height: 36px;
    }
  }
`;

const NavbarWrapper = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
    flex-wrap: wrap;
    margin-left: 50px;
  }
`;

const HamburgerMenu = styled.div<HamburgerMenuProps>`
  width: 26px;
  position: absolute;
  height: 20px;
  justify-content: space-between;
  flex-direction: column;
  right: 25px;
  top: 25px;
  display: flex;

  @media ${device.laptop} {
    display: none;
  }

  b {
    height: 2px;
    background: ${color.gainsboro};
    display: inline-block;
    width: 100%;
  }

  ${({ open }) =>
    open &&
    css`
      b:nth-child(1) {
        transform: rotate(45deg);
        position: relative;
        top: 50%;
      }

      b:nth-child(2) {
        display: none;
      }

      b:nth-child(3) {
        transform: rotate(-45deg);
        position: relative;
        top: -38%;
      }
    `}
`;

const MenuChild = styled.a`
  padding: 20px;
  text-align: left;
  white-space: nowrap;
  &.download {
    display: flex !important;
    gap: 8px;
    align-items: center;
  }

  @media ${device.tablet} {
    display: inline-block;
    color: ${({ theme }) =>
      theme.name === 'dark' ? '#fff' : `${color.primary + hexOpacity(80)}`};
    font-family: 'ProximaNovaLt';
    font-size: 17px;
    padding: 10px 20px;
  }

  @media ${device.laptop} {
    font-size: 19px;
  }
`;

const MenuAuth = styled.a<MenuAuthProps>`
  color: ${color.primary};
  font-family: ${({ bold }) =>
    bold ? 'var(--bold-font)' : 'var(--light-font)'};
  font-size: 17px;
  padding: 0 25px;
  text-transform: uppercase;

  ${({ bordered, theme }) =>
    bordered &&
    css`
      border: ${theme.border};
      border-radius: 3px;
      margin-right: 0px;
      padding-top: 2px;
    `}

  @media ${device.laptop} {
    font-size: 19px;
  }
`;

const FullPage = styled.div`
  transition-property: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: ${({ theme }) => theme?.background};
  height: 100%;
  position: relative;
`;

const MobileMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background:  ${({ theme }) => theme?.background};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 80px 0 0 0;
  overflow-auto;

  > div {
    background: ${({ theme }) => theme?.background};
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10%;
    border-radius: 4px;
    max-height: 100%;
    overflow: auto;
  }
`;

const MobileNav = styled.a<MobileNavProps>`
  color: ${({ theme }) => theme?.text};
  font-family: var(--light-font);
  font-size: 17px;
  padding: 20px;
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};

  @media ${device.tablet} {
    font-size: 19px;
  }
`;

const BetaLabelContainer = styled.div<{ size: 'small' | 'big' }>`
  display: flex;
  align-items: center;
  width: ${({ size }) => (size === 'small' ? '43px' : '86px')};
  height: ${({ size }) => (size === 'small' ? '20px' : '40px')};
  background: #8228bd;
  font-size: ${({ size }) => (size === 'small' ? '11px' : '22px')};
  line-height: ${({ size }) => (size === 'small' ? '14.85px' : '29.7px')};
  padding: ${({ size }) => (size === 'small' ? '2px 8px' : '4px 16px')};
  border-radius: ${({ size }) => (size === 'small' ? '4px' : '8px')};
  font-weight: 700;
  font-family: 'ProximaNovaRg';
  color: #ffffff;
`;
export {
  GlobalStyle,
  ContentWrapper,
  HeaderLanding,
  HeaderWrapper,
  Logo,
  LogoMahakarya,
  NavbarWrapper,
  HamburgerMenu,
  MenuChild,
  MenuAuth,
  FullPage,
  MobileMenuWrapper,
  MobileNav,
  BetaLabelContainer,
};
