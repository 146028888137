import { Common } from '@stockbit/ui';
import Link from 'next/link';
import Image from 'global/Image';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import {
  FooterWrapper,
  Logo,
  ContentWrapper,
  MenuWrapper,
  DownloadWrapper,
  LinksWrapper,
  LogoWrapper,
} from './styled';
import SocialMedia from './SocialMedia';
import Copyright from './Copyright';
import { footerLinks, downloadLinks } from '../constants';

const { Flex, Text } = Common;

const Footer = ({
  whiteFooterBackground,
}: {
  whiteFooterBackground?: boolean;
}) => {
  const router = useRouter();
  const theme = useTheme().name;
  return (
    <>
      <FooterWrapper className={whiteFooterBackground ? 'bg-white' : ''}>
        <Logo
          src={
            router.pathname === '/tatakelola'
              ? '/images/stockbit-with-pt.svg'
              : `/images/stockbit${theme === 'dark' ? '-white' : ''}.svg`
          }
          alt="Stockbit"
        />

        <ContentWrapper>
          <LinksWrapper>
            <LogoWrapper>
              <Logo
                src={
                  router.pathname === '/tatakelola'
                    ? '/images/stockbit-with-pt.svg'
                    : `/images/stockbit${theme === 'dark' ? '-white' : ''}.svg`
                }
                alt="Stockbit"
                hideOnMobile
              />
              <SocialMedia />
            </LogoWrapper>
            <MenuWrapper>
              <Flex direction="column">
                {footerLinks.slice(0, 5).map((link) => (
                  <Link key={link.title} href={link.url}>
                    <a target="_blank" rel="noopener noreferrer">
                      {link.title}
                    </a>
                  </Link>
                ))}
              </Flex>
            </MenuWrapper>
            <MenuWrapper>
              <Flex direction="column">
                {footerLinks.slice(5).map((link) => (
                  <Link key={link.title} href={link.url}>
                    <a target="_blank" rel="noopener noreferrer">
                      {link.title}
                    </a>
                  </Link>
                ))}
              </Flex>
            </MenuWrapper>
            <SocialMedia hideOnMobile hideOnTablet />
            <DownloadWrapper>
              <Text as="p">Unduh Aplikasi</Text>
              {downloadLinks.map((link) => (
                <Link key={link.title} href={link.url}>
                  <a target="_blank" rel="noopener noreferrer">
                    <Image
                      src={link.imageUrl}
                      alt={link.title}
                      width="110"
                      height="36"
                    />
                  </a>
                </Link>
              ))}
            </DownloadWrapper>
          </LinksWrapper>
          <SocialMedia hideOnTablet />
        </ContentWrapper>
      </FooterWrapper>
      <Copyright />
    </>
  );
};

Footer.defaultProps = {
  whiteFooterBackground: false,
};

export default Footer;
