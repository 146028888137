/* eslint-disable space-infix-ops */
import { Common, device, color } from '@stockbit/ui';
import { Row } from 'antd';
import Image from 'global/Image';
import styled from 'styled-components';

const { Flex, Text } = Common;

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme?.backgroundSecondary};
  padding: 27px 20px;

  @media ${device.tablet} {
    padding: 50px 30px;
  }
  @media ${device.laptop} {
    padding: 80px 0 50px 0;
  }
  &.bg-white {
    background-color: #ffffff;
  }
`;

const LinksWrapper = styled(Flex).attrs({
  align: 'flex-start',
  justify: 'space-between',
})`
  padding: 40px 0 35px;

  @media ${device.laptop} {
    width: 90%;
    padding: 0px;
  }
`;

const ContentWrapper = styled.section`
  max-width: 1300px;
  margin: auto;

  @media ${device.tablet} {
    padding: 20px 0;
  }

  @media ${device.laptop} {
    width: calc(100% - 150px);
  }
`;

interface LogoProps {
  hideOnMobile?: boolean;
}
const Logo = styled(Image)<LogoProps>`
  width: 74px;
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};

  @media ${device.tablet} {
    display: ${({ hideOnMobile }) => (!hideOnMobile ? 'none' : 'block')};
    width: auto;
    height: 54px;
  }

  @media ${device.laptop} {
    height: 77px;
    width: 141px;
    object-fit: contain;
  }
`;

const LinkWrapper = styled(Flex)`
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const FooterLink = styled.a`
  color: #777;
  font-family: var(--regular-font);
  font-size: 14px;
  padding-bottom: 6px;

  @media ${device.tablet} {
    padding-bottom: 10px;
  }
`;

interface HiddenFooterProps {
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  [key: string]: any;
}
const SocialMediaWrapper = styled(Flex).attrs<HiddenFooterProps>({
  align: 'center',
  justify: 'space-between',
  py: '10px',
})`
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  width: 100px;

  @media ${device.tablet} {
    display: ${({ hideOnTablet }) => (hideOnTablet ? 'none' : 'flex')};
  }

  @media ${device.laptop} {
    display: ${({ hideOnMobile }) => (!hideOnMobile ? 'none' : 'flex')};
  }

  & img {
    filter: grayscale(1);
    height: 17px;

    @media ${device.tablet} {
      height: 20px;
    }
  }
`;

const LogoWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

const MenuWrapper = styled(Flex)`
  align-items: flex-start;
  flex-direction: row;
  justify-items: space-between;

  .hidden-navs {
    display: flex;
  }

  @media ${device.tablet} {
    align-items: center;
    order: unset;
  }

  @media ${device.laptop} {
    .hidden-navs {
      display: none;
    }
  }

  ${Flex} {
    @media ${device.tablet} {
      align-items: flex-start;
    }
  }

  a {
    color: inherit;
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 10px;

    &:hover {
      color: #777;
    }

    @media ${device.tablet} {
      color: ${({ theme }) => theme?.textSecondary};
      line-height: 1.15;
    }
  }
`;

const DownloadWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  order: 1;

  @media ${device.tablet} {
    order: unset;
  }

  img {
    margin-bottom: 12px;
  }

  p {
    color: inherit;
    font-size: 14px;
    text-decoration: none;
    line-height: 20px;
    padding: 5px 0;

    @media ${device.tablet} {
      color: ${({ theme }) => theme?.textSecondary};
      margin-bottom: 10px;
      line-height: 1.15;
    }
  }
`;

const CopyrightWrapper = styled.section`
  background-color: ${({ theme }) => theme?.backgroundSecondary};
`;

const CopyrightContentRow = styled(Row)`
  width: 100%;
`;

const CopyrightContentWrapper = styled(Row)`
  margin: auto;
  align-items: center;
  flex-direction: column-reverse;
  padding: 50px 25px;
  width: 100%;
  max-width: 1300px;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 0px;
    width: calc(100% - 150px);
    ${({ theme }) =>
      theme?.name === 'dark' &&
      `
      filter: saturate(2);
    `}
  }
`;

const CopyImage = styled(Flex).attrs({ align: 'center', justify: 'center' })`
  img {
    filter: grayscale(${({ theme }) => (theme.name === 'dark' ? 0 : 1)});
    object-fit: contain;
    height: 60px;
    width: 40px;
    margin: auto;

    @media ${device.tablet} {
      width: 80px;
      height: 80px;
    }

    @media ${device.laptop} {
      width: 120px;
      height: 120px;
    }
  }
`;

const CopyDescription = styled(Text).attrs({
  align: 'center',
  family: 'bold',
  color: '#949494',
})`
  font-size: 12px;
  display: none;
  color: ${({ theme }) => theme.textTertiery};
  @media ${device.tablet} {
    display: block;
    font-size: 8px;
  }

  @media ${device.laptop} {
    font-size: 12px;
  }
`;

const FooterCopyrightImage = styled(Image)`
  width: 164px;
  opacity: 0.5;

  @media ${device.laptop} {
    width: auto;
  }
`;

export {
  FooterWrapper,
  ContentWrapper,
  LinkWrapper,
  FooterLink,
  Logo,
  SocialMediaWrapper,
  MenuWrapper,
  DownloadWrapper,
  CopyrightWrapper,
  CopyrightContentRow,
  CopyrightContentWrapper,
  CopyImage,
  CopyDescription,
  LinksWrapper,
  LogoWrapper,
  FooterCopyrightImage,
};
