import { Common } from '@stockbit/ui';
import Image from 'global/Image';
import { ReactNode } from 'react';
import { CopyImage, CopyDescription } from './styled';

const { Flex } = Common;

interface CopyrightLogoProps {
  title: string;
  imageUrl: string;
  description: string | ReactNode;
}

function CopyrightLogo({ title, imageUrl, description }: CopyrightLogoProps) {
  return (
    <Flex align="center" direction="column" style={{ maxWidth: '147.5px' }}>
      <CopyImage>
        <Image src={imageUrl} alt={title} />
      </CopyImage>
      <CopyDescription>{description}</CopyDescription>
    </Flex>
  );
}

export default CopyrightLogo;
